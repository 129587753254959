import React, { ReactElement, useState } from 'react';
import ChuteImage from '../../assets/component_dimensions/ChuteData.svg';
import styled from 'styled-components';
import { CategoryHeader } from '../Labels/CategoryHeader';
import { DataInput } from '../Inputs/DataInput';
import { useHandleInputChange, useHandleOnBlurChange } from './InputGroupHooks';
import { CalculationSectionProps, ChuteDataInputs } from './inputGroupTypes';
import { DataInputs } from '../Inputs/InputTypes';
import { CalculationDataInputs } from '../CalculationsTab/calculationsTabTypes';
import { TooltipModal } from '../Tooltip/TooltipModal';
import { initialChuteData } from '../InitialData/InitialData';

const valueLimits: Record<
  keyof ChuteDataInputs['data'],
  { min: number; max: number }
> = {
  frictionCoef: {
    min: 0,
    max: 10,
  },
  cableChuteAngle: {
    min: 0,
    max: 90,
  },
};

function validateValue<K extends keyof ChuteDataInputs['data']>(
  key: K,
  value: number
): boolean {
  const limits = valueLimits[key];
  return value >= limits.min && value <= limits.max;
}

export function Chute(props: CalculationSectionProps): ReactElement {
  const initialData: CalculationDataInputs =
    props.applicationData.calculationsData.find(
      (section: CalculationDataInputs): boolean => section.id === props.id
    ) || initialChuteData;
  const [chuteData, setChuteData] = useState<ChuteDataInputs>(
    initialData as ChuteDataInputs
  );

  const handleInputChange = useHandleInputChange(setChuteData);
  const handleOnBlurChange = useHandleOnBlurChange({
    id: props.id,
    data: chuteData,
    calculationDataState: props.calculationDataState,
  });

  return (
    <>
      <StyledDiv>
        <InputSection>
          <CategoryHeader title={chuteData.title} underline={false} />
          {Object.entries(chuteData.data).map(
            ([key, input]: [string, DataInputs]) => {
              const isValueOk = (value: number) =>
                validateValue(key as keyof ChuteDataInputs['data'], value);
              return (
                <DataInput
                  key={key}
                  name={input.name}
                  symbol={input.symbol}
                  value={input.value}
                  placeholder={input.value}
                  unit={input.unit}
                  infoIcon={
                    (key as keyof ChuteDataInputs['data']) === 'frictionCoef'
                  }
                  infoCallback={() => console.log('info clicked')}
                  infoModal={<TooltipModal />}
                  valueOk={isValueOk(input.value)}
                  onChange={handleInputChange(
                    key as keyof ChuteDataInputs['data'],
                    isValueOk
                  )}
                  onBlur={handleOnBlurChange}
                  restrictionText={
                    key === 'frictionCoef'
                      ? 'Value must be between 0 and 10'
                      : 'Value must be between 0 and 90'
                  }
                />
              );
            }
          )}
        </InputSection>
        <div>
          <img src={ChuteImage} alt={''} />
        </div>
      </StyledDiv>
    </>
  );
}

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 60%;
  gap: 0.8em;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;
