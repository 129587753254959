import { Arrow, Circle, Layer, Line, Stage, Text } from 'react-konva';
import React, { RefObject, useEffect, useState } from 'react';
import { CatenaryDataInputs } from '../InputGroup/inputGroupTypes';
import { CatenaryCoordinates } from '../RightSection/rightSectionTypes';
import { DataInputs } from '../Inputs/InputTypes';
import Konva from 'konva';

interface CatenaryVisualizationProps {
  catenaryData: CatenaryDataInputs;
  catenaryCoordinates: CatenaryCoordinates;
  catenaryVisualizationRef: RefObject<Konva.Stage>;
}

interface Point {
  x: number;
  y: number;
  highest?: boolean;
}

const initialPoint = {
  x: 0,
  y: 0,
  highest: false,
};

export function CatenaryVisualization(props: CatenaryVisualizationProps) {
  const [pointList, setPointList] = useState<number[]>([]);
  const [errorPointList, setErrorPointList] = useState<number[]>([]);
  const [pointA, setPointA] = useState<Point>(initialPoint);
  const [pointB, setPointB] = useState<Point>(initialPoint);
  const [pointC, setPointC] = useState<Point>(initialPoint);

  const [h1, setH1] = useState<DataInputs>({
    name: 'Vertical distance A to B',
    symbol: 'H_1',
    value: 7.5,
    unit: 'm',
    valueOk: true,
  });

  const [h2, setH2] = useState<DataInputs>({
    name: 'Vertical distance B to C',
    symbol: 'H_2',
    value: 6.3,
    unit: 'm',
    valueOk: true,
  });

  const [w, setW] = useState<DataInputs>({
    name: 'Horizontal distance A to C',
    symbol: 'W',
    value: 28.2,
    unit: 'm',
    valueOk: true,
  });

  useEffect(() => {
    const xCoords = props.catenaryCoordinates.xCoords || [];
    const yCoords = props.catenaryCoordinates.yCoords || [];
    const isValidList = props.catenaryCoordinates.isValidList;
    if (xCoords.length > 0 && yCoords.length > 0) {
      const pA = { x: xCoords[0], y: -yCoords[0] };
      const pC = {
        x: xCoords[xCoords.length - 1],
        y: -yCoords[yCoords.length - 1],
      };
      const length = pC.x - pA.x;
      const height = -1 * (pA.y < pC.y ? pA.y : pC.y);
      const ratio = 250 / (length > height ? length : height);
      const paddingX = (450 - ratio * length) / 2;
      const paddingY = 290;
      setPointA({
        x: paddingX + ratio * pA.x,
        y: paddingY + ratio * pA.y,
        highest: pA.y < pC.y,
      });
      setPointC({
        x: paddingX + ratio * pC.x,
        y: paddingY + ratio * pC.y,
        highest: pC.y < pA.y,
      });
      let lowestPoint = { x: 0, y: 0, highest: false };
      const newErrorPointList: number[] = [];
      const newPointList: number[] = xCoords.reduce((result, _, i) => {
        const xPoint = paddingX + ratio * xCoords[i];
        const yPoint = paddingY - ratio * yCoords[i];
        if (lowestPoint.y < yPoint) {
          lowestPoint = { x: xPoint, y: yPoint, highest: false };
        }
        result.push(xPoint);
        result.push(yPoint);
        if (!isValidList[i]) {
          newErrorPointList.push(xPoint);
          newErrorPointList.push(yPoint);
        }
        return result;
      }, [] as number[]);
      setPointB(lowestPoint);
      setPointList(newPointList);
      setErrorPointList(newErrorPointList);
      setH1(props.catenaryData.data.h1distance);
      setH2(props.catenaryData.data.h2distance);
      setW(props.catenaryData.data.dcDistance);
    }
  }, [props.catenaryCoordinates]);

  return (
    <>
      <Stage ref={props.catenaryVisualizationRef} width={450} height={300}>
        <Layer>
          <InputArrow
            valueName={'H1'}
            value={h1.value}
            unit={h1.unit}
            firstPoint={pointA}
            secondPoint={pointB}
            side={'left'}
          />
          <InputArrow
            valueName={'H2'}
            value={h2.value}
            unit={h2.unit}
            firstPoint={pointC}
            secondPoint={pointB}
            side={'right'}
          />
          <InputArrow
            valueName={'W'}
            value={w.value}
            unit={w.unit}
            firstPoint={pointA.highest ? pointA : pointC}
            secondPoint={pointA.highest ? pointC : pointA}
            side={'top'}
          />
          <Line
            key="catenaryline"
            x={0}
            y={0}
            points={pointList}
            stroke={'rgb(0, 173, 239)'}
            strokeWidth={6}
          />
          <Line
            key="ErrorCatenaryline"
            x={0}
            y={0}
            points={errorPointList}
            stroke={'red'}
            strokeWidth={6}
          />
          <PointVisual name={'A'} point={pointA} />
          <PointVisual name={'B'} point={pointB} />
          <PointVisual name={'C'} point={pointC} />
        </Layer>
      </Stage>
    </>
  );
}

interface PointVisualProps {
  name: string;
  point: Point;
}

function PointVisual(props: PointVisualProps) {
  const [textPosition, setTextPosition] = useState<Point | null>(null);

  useEffect(() => {
    const point = props.point;
    if (props.name === 'A') {
      setTextPosition({ x: point.x + 15, y: point.y - 10 });
    }
    if (props.name === 'B') {
      setTextPosition({ x: point.x - 5, y: point.y - 20 });
    }
    if (props.name === 'C') {
      setTextPosition({ x: point.x - 20, y: point.y - 10 });
    }
  }, [props]);

  return (
    <>
      {textPosition && (
        <Text
          x={textPosition.x}
          y={textPosition.y}
          text={props.name}
          fontSize={11}
          fontStyle={'bold'}
        />
      )}
      <Circle x={props.point.x} y={props.point.y} radius={6} fill="white" />
      <Circle x={props.point.x} y={props.point.y} radius={4} fill="red" />
    </>
  );
}

type Sides = 'left' | 'top' | 'right';
interface InputArrowProps {
  valueName: string;
  value: number;
  unit: string;
  firstPoint: Point;
  secondPoint: Point;
  side: Sides;
}

function InputArrow(props: InputArrowProps) {
  const [points, setPoints] = useState<number[]>([]);
  const [textPosition, setTextPosition] = useState<Point | null>(null);
  useEffect(() => {
    const firstPoint = props.firstPoint;
    const secondPoint = props.secondPoint;
    if (props.side === 'left') {
      setPoints([
        firstPoint.x - 20,
        firstPoint.y,
        firstPoint.x - 20,
        secondPoint.y,
      ]);
      setTextPosition({
        x: firstPoint.x - 85,
        y: Math.abs(secondPoint.y + firstPoint.y - 10) / 2,
      });
    }
    if (props.side === 'top') {
      setPoints([
        firstPoint.x,
        firstPoint.y - 20,
        secondPoint.x,
        firstPoint.y - 20,
      ]);
      setTextPosition({
        x: Math.abs(secondPoint.x + firstPoint.x - 65) / 2,
        y: firstPoint.y - 40,
      });
    }
    if (props.side === 'right') {
      setPoints([
        firstPoint.x + 20,
        firstPoint.y,
        firstPoint.x + 20,
        secondPoint.y,
      ]);
      setTextPosition({
        x: firstPoint.x + 25,
        y: Math.abs(secondPoint.y + firstPoint.y - 10) / 2,
      });
    }
  }, [props]);

  return (
    <>
      {textPosition && (
        <Text
          x={textPosition.x}
          y={textPosition.y}
          text={`${props.valueName} = ${props.value} ${props.unit}`}
          fontSize={11}
        />
      )}
      <Line
        x={0}
        y={0}
        points={[points[0], points[1], props.firstPoint.x, props.firstPoint.y]}
        stroke={'black'}
        strokeWidth={1}
      />
      <Line
        x={0}
        y={0}
        points={[
          points[2],
          points[3],
          props.secondPoint.x,
          props.secondPoint.y,
        ]}
        stroke={'black'}
        strokeWidth={1}
        dash={[5, 5, 5, 5]}
      />
      <Arrow
        x={0}
        y={0}
        points={points}
        stroke={'black'}
        strokeWidth={1}
        fill={'black'}
        pointerAtBeginning
        pointerAtEnding
      />
    </>
  );
}
