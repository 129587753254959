import { CategoryHeader } from '../Labels/CategoryHeader';
import React, { ReactElement, useEffect, useState } from 'react';
import type { ResultLabel } from './rightSectionTypes';
import { DataLabel } from '../Labels/DataLabel';
import { Divider } from '../Divider/Divider';
import styled from 'styled-components';

export interface ScenarioResultsProps {
  title: string;
  data: ResultLabel[];
  fallbackData: boolean;
}
export function ScenarioResultSection(
  props: ScenarioResultsProps
): ReactElement {
  const [resultsData, setResultsData] = useState<ScenarioResultsProps>(props);

  useEffect((): void => {
    setResultsData(props);
  }, [props]);
  return (
    <>
      <StyledDiv>
        <Divider />
      </StyledDiv>
      <CategoryHeader title={props.title} underline={false} />
      {Object.entries(resultsData.data).map(
        ([key, entry]: [string, ResultLabel]) => (
          <DataLabel
            key={key}
            name={entry.label}
            value={props.fallbackData ? entry.value : '??'}
            symbol={entry.symbol}
            unit={entry.unit}
          />
        )
      )}
    </>
  );
}

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  padding: 0.3em 0 0.3em 0;
`;
