import {
  BowCableInputs,
  CatenaryDataInputs,
  ChuteDataInputs,
  ModularCableInputs,
} from '../InputGroup/inputGroupTypes';

export const initialBceData: BowCableInputs = {
  title: 'BCE',
  data: {
    h1distance: {
      name: 'Vertical distance A to B',
      symbol: 'H_1',
      value: 15,
      unit: 'm',
      valueOk: true,
    },
    h2distance: {
      name: 'Vertical distance C to D',
      symbol: 'H_2',
      value: 15,
      unit: 'm',
      valueOk: true,
    },
    w1distance: {
      name: 'Horizontal distance A to B',
      symbol: 'W_1',
      value: 25,
      unit: 'm',
      valueOk: true,
    },
    w2distance: {
      name: 'Horizontal distance C to D',
      symbol: 'W_2',
      value: 5,
      unit: 'm',
      valueOk: true,
    },
    frictionCoef: {
      name: 'Friction Coeff. of Arm Roller Bearings',
      symbol: 'µ_rb',
      value: 0.15,
      unit: '',
      valueOk: true,
    },
  },
};

export const initialCatenaryData: CatenaryDataInputs = {
  title: 'Catenary',
  data: {
    h1distance: {
      name: 'Vertical distance A to B',
      symbol: 'H_1',
      value: 7.5,
      unit: 'm',
      valueOk: true,
    },
    h2distance: {
      name: 'Vertical distance B to C',
      symbol: 'H_2',
      value: 6.3,
      unit: 'm',
      valueOk: true,
    },
    dcDistance: {
      name: 'Horizontal distance A to C',
      symbol: 'W',
      value: 28.2,
      unit: 'm',
      valueOk: true,
    },
  },
};

export const initialChuteData: ChuteDataInputs = {
  title: 'Chute',
  data: {
    frictionCoef: {
      name: 'Friction coefficient cable - chute',
      symbol: 'µ_ch',
      value: 0.15,
      unit: '',
      valueOk: true,
    },
    cableChuteAngle: {
      name: 'Angle in horizontal plane cable - chute',
      symbol: 'α_2',
      value: 30,
      unit: '°',
      valueOk: true,
    },
  },
};

export const initialMCTData: ModularCableInputs = {
  title: 'MCT',
  data: {
    input: [
      {
        length: {
          value: 15,
          unit: 'm',
          valueOk: true,
        },
        angle: {
          value: 0,
          unit: 'deg',
          valueOk: true,
        },
        friction: {
          value: 0.15,
          unit: '',
          valueOk: true,
        },
      },
    ],
    rotation: 0,
  },
};
