import { ResultLabel, type ResultsDataType } from './rightSectionTypes';
export const initialLceResultsScenario1: ResultsDataType = {
  cableLengthAC: { value: 0, unit: 'm' },
  verticalChuteAngle: { value: 0, unit: '°' },
  catenary: { value: 0, unit: 'kN' },
  chute: { value: 0, unit: 'kN' },
  modularCableTrack: { value: 0, unit: 'kN' },
  reqCapacityLCE: { value: 0, unit: 'kN' },
  designCapacityLCE: { value: 0, unit: 'kN' },
};

export const initialLceResultsScenario3: ResultsDataType = {
  cableTensionStartMCT: { value: 0, unit: 'kN' },
  modularCableTrack: { value: 0, unit: 'kN' },
  reqCapacityLCE: { value: 0, unit: 'kN' },
  designCapacityLCE: { value: 0, unit: 'kN' },
};

export const initialLceResultsScenario4a: ResultsDataType = {
  cableLengthAC: { value: 0, unit: 'm' },
  verticalChuteAngle: { value: 0, unit: '°' },
  catenary: { value: 0, unit: 'kN' },
  chute: { value: 0, unit: 'kN' },
  reqCapacityLCE: { value: 0, unit: 'kN' },
  designCapacityLCE: { value: 0, unit: 'kN' },
};

export const initialBceResultScenario1: ResultsDataType = {
  cableLengthAB: { value: 0, unit: 'm' },
  cableLengthCD: { value: 0, unit: 'm' },
  reqCapacityBCE: { value: 0, unit: 'kN' },
  designCapacityBCE: { value: 0, unit: 'kN' },
};

export const initialBceResultScenario2: ResultsDataType = {
  cableLengthAB: { value: 0, unit: 'm' },
  cableLengthCD: { value: 0, unit: 'm' },
  cableTensionStartMCT: { value: 0, unit: 'kN' },
  modularCableTrack: { value: 0, unit: 'kN' },
  reqCapacityBCE: { value: 0, unit: 'kN' },
  designCapacityBCE: { value: 0, unit: 'kN' },
};

const commonLceResults = [
  {
    label: 'Suspended Cable Length A-C',
    unit: 'm',
    value: 0,
    key: 'cableLengthAC',
    symbol: 'L_cac',
  },
  {
    label: 'Vertical Chute Angle',
    unit: '°',
    value: 0,
    key: 'verticalChuteAngle',
    symbol: 'α_1',
  },
  { label: 'Catenary', unit: 'kN', value: 0, key: 'catenary', symbol: 'T_3' },
  { label: 'Chute', unit: 'kN', value: 0, key: 'chute', symbol: 'F_ch' },
  {
    label: 'Modular Cable Track',
    unit: 'kN',
    value: 0,
    key: 'modularCableTrack',
    symbol: 'F_mct',
  },
  {
    label: 'Required Capacity LCE',
    unit: 'kN',
    value: 0,
    key: 'reqCapacityLCE',
    symbol: 'F_lce',
  },
  {
    label: 'Design Capacity LCE',
    unit: 'kN',
    value: 0,
    key: 'designCapacityLCE',
    symbol: 'F_lce,d',
  },
];

const commonBceResults: ResultLabel[] = [
  {
    label: 'Suspended Cable Length A-B',
    unit: 'm',
    value: 0,
    key: 'cableLengthAB',
    symbol: 'L_cab',
  },
  {
    label: 'Suspended Cable Length C-D',
    unit: 'm',
    value: 0,
    key: 'cableLengthCD',
    symbol: 'L_ccd',
  },
  {
    label: 'Required Capacity BCE',
    unit: 'kN',
    value: 0,
    key: 'reqCapacityBCE',
    symbol: 'F_bce',
  },
  {
    label: 'Design Capacity BCE',
    unit: 'kN',
    value: 0,
    key: 'designCapacityBCE',
    symbol: 'F_bce,d',
  },
];

const cableTensionMCT = {
  label: 'Cable Tension Start MCT',
  unit: 'kN',
  value: 0,
  key: 'cableTensionStartMCT',
  symbol: 'T_1',
};

export const LceResultsLabelsScenario1: ResultLabel[] = [...commonLceResults];

export const LceResultsLabelsScenario3: ResultLabel[] = [
  cableTensionMCT,
  {
    label: 'Modular Cable Track',
    unit: 'kN',
    value: 0,
    key: 'modularCableTrack',
    symbol: 'F_mct',
  },
  {
    label: 'Required Capacity LCE',
    unit: 'kN',
    value: 0,
    key: 'reqCapacityLCE',
    symbol: 'F_lce',
  },
  {
    label: 'Design Capacity LCE',
    unit: 'kN',
    value: 0,
    key: 'designCapacityLCE',
    symbol: 'F_lce,d',
  },
];

export const LceResultsLabelsScenario4a: ResultLabel[] = [
  {
    label: 'Suspended Cable Length A-C',
    unit: 'm',
    value: 0,
    key: 'cableLengthAC',
    symbol: 'L_cac',
  },
  {
    label: 'Vertical Chute Angle',
    unit: '°',
    value: 0,
    key: 'verticalChuteAngle',
    symbol: 'α_1',
  },
  { label: 'Catenary', unit: 'kN', value: 0, key: 'catenary', symbol: 'T_3' },
  { label: 'Chute', unit: 'kN', value: 0, key: 'chute', symbol: 'F_ch' },
  {
    label: 'Required Capacity LCE',
    unit: 'kN',
    value: 0,
    key: 'reqCapacityLCE',
    symbol: 'F_lce',
  },
  {
    label: 'Design Capacity LCE',
    unit: 'kN',
    value: 0,
    key: 'designCapacityLCE',
    symbol: 'F_lce,d',
  },
];

export const BceResultLabelsScenario1: ResultLabel[] = [...commonBceResults];

export const BceResultLabelsScenario2: ResultLabel[] = [
  commonBceResults[0],
  commonBceResults[1],
  cableTensionMCT,
  {
    label: 'Modular Cable Track',
    unit: 'kN',
    value: 0,
    key: 'modularCableTrack',
    symbol: 'F_mct',
  },
  commonBceResults[2],
  commonBceResults[3],
];
