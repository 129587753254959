import React, { ReactElement } from 'react';
import MCTImage from '../../assets/component_dimensions/MctData.svg';
export function TooltipMCT(): ReactElement {
  return (
    <img
      src={MCTImage}
      alt=""
      style={{
        height: '75%',
        width: '75%',
        border: 'solid 2px var(--twd_aqua)',
        borderRadius: '8px',
        background: 'white',
      }}
    />
  );
}
