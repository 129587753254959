import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { TextCheckboxProps } from './InputTypes';

export function TextCheckbox(props: TextCheckboxProps): ReactElement {
  return (
    <StyledDiv>
      <NameDiv>{props.label}</NameDiv>
      <StyledCheckbox $checked={props.value}>
        <Checkbox
          name={props.name}
          id={props.name}
          checked={props.value}
          onChange={props.onChange}
        />
        <Label htmlFor={props.name} $checked={props.value}></Label>
      </StyledCheckbox>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  height: 40px;
  width: 100%;
  min-width: 350px;
  gap: 0.5em;
  color: #333333;
  font-size: 18px;
  line-height: 1.4em;
  font-family: Arial, sans-serif;
`;

const StyledCheckbox = styled.div<{ $checked: boolean }>`
  margin-left: 0.5em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 27px;
  height: 8px;
  padding: 0 16px 0 16px;
  box-sizing: border-box;
  background: ${({ $checked }) => ($checked ? '#b7defa' : '#ebe9e9')};
  position: relative;
  border-radius: 50px;
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  visibility: hidden;

  &:checked + label {
    left: 20px;
  }
`;

const Label = styled.label<{ $checked: boolean }>`
  display: block;
  width: 16px;
  height: 16px;
  position: absolute;
  top: -6px;
  left: -7px;
  cursor: pointer;
  background: ${({ $checked }) => ($checked ? '#00adef' : '#8c8c8c')};
  border-radius: 50px;
  transition: all 0.4s ease;
  border: solid 2px #ffffff;

  &:hover {
    background: #00306b;
  }
`;

const NameDiv = styled.div`
  flex-basis: 50%;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
`;
