import React, { ReactElement, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { CategoryHeader } from '../Labels/CategoryHeader';
import { DataInput } from '../Inputs/DataInput';
import {
  updateCatenaryData,
  useHandleInputChange,
  useHandleOnBlurChange,
} from './InputGroupHooks';
import { CalculationSectionProps, CatenaryDataInputs } from './inputGroupTypes';
import { DataInputs } from '../Inputs/InputTypes';
import { CalculationDataInputs } from '../CalculationsTab/calculationsTabTypes';
import { initialCatenaryData } from '../InitialData/InitialData';
import { CatenaryVisualization } from '../Vizualisations/Catenary';
import Konva from 'konva';
import Stage = Konva.Stage;
import { useAppData } from '../Contexts/DataContext/DataContext';
import { ReactComponent as CopyIcon } from '../../assets/buttons_and_icons/CopyIcon.svg';
import { ReactComponent as AlertIcon } from '../../assets/buttons_and_icons/Badge_alert.svg';
import { Tooltip } from '@mui/material';
import { CatenaryCoordinates } from '../RightSection/rightSectionTypes';

export function Catenary(props: CalculationSectionProps): ReactElement {
  const { catenaryDataCoords, setCatenaryDataCoords } = useAppData();

  useEffect(() => {
    if (props.resultData) {
      const catenaryCoordinateList = props.resultData.data.catenaryCoordinates;
      const catenaryCoordinate3 = catenaryCoordinateList[2] || [];
      let catenaryCoordinates = catenaryCoordinateList[0];
      if (props.id === 'Catenary-2') {
        catenaryCoordinates = catenaryCoordinateList[1];
      }
      if (catenaryCoordinate3.length > 0 && catenaryCoordinate3[0].length > 0) {
        catenaryCoordinates = catenaryCoordinate3;
      }
      updateCatenaryData(
        props.id,
        catenaryCoordinates[0] as number[],
        catenaryCoordinates[1] as number[],
        catenaryCoordinates[2] as boolean[],
        setCatenaryDataCoords
      );
    }
  }, [props.resultData, props.id]);

  const containerRef = useRef<HTMLDivElement>(null);

  const initialData: CalculationDataInputs =
    props.applicationData.calculationsData.find(
      (section: CalculationDataInputs): boolean => section.id === props.id
    ) || initialCatenaryData;
  const [catenaryData, setCatenaryData] = useState<CatenaryDataInputs>(
    initialData as CatenaryDataInputs
  );

  const handleInputChange = useHandleInputChange(setCatenaryData);
  const handleOnBlurChange = useHandleOnBlurChange({
    id: props.id,
    data: catenaryData,
    calculationDataState: props.calculationDataState,
  });

  const catenaryVisualizationRef = useRef<Stage>(null);

  const catenaryDataCoordsId = catenaryDataCoords.find(
    (item: CatenaryCoordinates) => props.id === item.id
  ) || {
    id: props.id,
    xCoords: [],
    yCoords: [],
    isValidList: [],
  };

  const clickOnButton = async () => {
    const xCoords = catenaryDataCoordsId.xCoords || [];
    const yCoords = catenaryDataCoordsId.yCoords || [];
    const coordinateString = xCoords.reduce(
      (result: string, xCoord: number, index: number) => {
        const x = (xCoord * 1000).toFixed(0).toString();
        const y = (yCoords[index] * 1000).toFixed(0).toString();
        result += `${x},${y}\n`;
        return result;
      },
      '_PLINE\n'
    );
    await window.navigator.clipboard.writeText(coordinateString);
  };

  return (
    <>
      <StyledDiv ref={containerRef}>
        <InputSection>
          <CategoryHeader
            title="Catenary"
            underline={false}
            extraElement={
              <Tooltip
                title="Copy coordinates to clipboard"
                placement={'top'}
                arrow
              >
                <CopyToClipBoardButton onClick={() => void clickOnButton()}>
                  <CopyIcon />
                </CopyToClipBoardButton>
              </Tooltip>
            }
          />

          {Object.entries(catenaryData.data).map(
            ([key, input]: [string, DataInputs]) => {
              const isValueOk = (value: number) => value > 0 && value < 1000;
              return (
                <DataInput
                  key={key}
                  name={input.name}
                  symbol={input.symbol}
                  value={input.value}
                  placeholder={input.value}
                  unit={input.unit}
                  valueOk={isValueOk(input.value)}
                  onChange={handleInputChange(
                    key as keyof CatenaryDataInputs['data'],
                    isValueOk
                  )}
                  onBlur={handleOnBlurChange}
                  restrictionText={'Value must be between 0 and 1000'}
                />
              );
            }
          )}
          {catenaryDataCoordsId.isValidList.some(
            (valid: boolean) => !valid
          ) && (
            <WarningBox>
              <AlertIcon style={{ marginRight: '0.5rem' }} />
              Defined catenary falls below minimum bending radius (of cable)
            </WarningBox>
          )}
        </InputSection>
        <CatenaryVisualization
          catenaryData={catenaryData}
          catenaryCoordinates={catenaryDataCoordsId}
          catenaryVisualizationRef={catenaryVisualizationRef}
        />
      </StyledDiv>
    </>
  );
}

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 60%;
  gap: 0.8em;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const CopyToClipBoardButton = styled.button`
  width: 30px;
  height: 30px;
  background-color: white;
  border: 0;
  border-radius: 8px;
  text-align: center;
  margin: 0;
  cursor: pointer;
  color: #333333;
  font-size: 18px;
  line-height: 1.4em;
  font-family: Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-items: center;
  gap: 5px;

  svg {
    fill: var(--twd_web_grey);
  }

  &:hover {
    svg {
      fill: var(--twd_aqua);
    }
  }
`;

export const WarningBox = styled.div`
  color: var(--twd_red);
  text-align: left;
  display: flex;
  align-items: center;
  z-index: 5;

  svg {
    fill: var(--twd_red);
  }
`;
