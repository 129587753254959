import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ImageLabelProps } from './labelTypes';

export function ImageLabel({ title }: ImageLabelProps): ReactElement {
  // Checks if there is a space in the image label, if so but the second part on a new line.
  const [isThereSpace, setIsThereSpace] = useState<boolean>(false);
  useEffect(() => {
    setIsThereSpace(title.includes(' '));
  }, [title]);
  return (
    <StyledHeader>
      {isThereSpace ? (
        <>
          <DoubleLineDiv>{title.split(' ')[0]}</DoubleLineDiv>
          <DoubleLineDiv>{title.split(' ').slice(1).join(' ')}</DoubleLineDiv>
        </>
      ) : (
        title
      )}
    </StyledHeader>
  );
}

const StyledHeader = styled.div`
  position: absolute;
  top: 105%;
  background-color: transparent;
  height: 60px;
  color: #333333;
  border: none;
  font-size: 20px;
  font-family: Arial, sans-serif;
  font-weight: 400;
  line-height: 140%;
  display: inline-block;
  width: fit-content;

  @media (max-width: 840px) {
    font-size: 16px;
  }

  @media (max-width: 640px) {
    font-size: 12px;
  }
`;

const DoubleLineDiv = styled.div`
  display: flex;
  justify-content: center;
`;
