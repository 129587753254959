import React, {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { TextInput } from '../Inputs/TextInput';
import styled from 'styled-components';
import { CategoryHeader } from '../Labels/CategoryHeader';
import { TextCheckbox } from '../Inputs/TextCheckbox';
import { ReferenceInput } from '../Inputs/ReferencesInput';
import {
  ProjectData,
  ReportData,
  ReportInfoFields,
  ReportInfoTextFields,
  TextInputConfiguration,
} from './reportsTabTypes';
import { useReferences } from './reportsTabHooks';
import { useAppData } from '../Contexts/DataContext/DataContext';
import { fetchReport } from '../../services/swegApi';
import { SubmitButton } from '../Button/SubmitButton';
import { CircleLoader } from '../CircleLoader/CircleLoader';
import {
  ApplicationDataType,
  ImageType,
} from '../Contexts/DataContext/dataContextType';
import { ModularCableTrackVisualizations } from '../Vizualisations/ModularCableTrack';
import { CalculationDataInputs } from '../CalculationsTab/calculationsTabTypes';
import {
  CatenaryDataInputs,
  ModularCableInputs,
} from '../InputGroup/inputGroupTypes';
import { CatenaryVisualization } from '../Vizualisations/Catenary';
import { CatenaryCoordinates } from '../RightSection/rightSectionTypes';
import Konva from 'konva';
import Stage = Konva.Stage;
import {
  initialCatenaryData,
  initialMCTData,
} from '../InitialData/InitialData';
import { updateCatenaryData } from '../InputGroup/InputGroupHooks';

const inputConfigurations: TextInputConfiguration[] = [
  {
    label: 'Project Title',
    name: 'projectTitle',
    placeholder: 'Cable Transfer XXX',
  },
  {
    label: 'Calculation Title',
    name: 'calculationTitle',
    placeholder: 'Cable Pulling Force Assessment',
  },
  {
    label: 'Calculation Number',
    name: 'calculationNumber',
    placeholder: 'C01',
  },
  { label: 'Author', name: 'author', placeholder: 'WCS Engineering' },
  { label: 'Revision', name: 'revision', placeholder: 'R01' },
  { label: 'Checked', name: 'checked', placeholder: 'Project Engineer' },
];

export function ReportsTab(): ReactElement {
  const {
    projectData,
    applicationData,
    setApplicationData,
    appResultsData,
    resultsLoading,
    setProjectData,
    containerWidth,
    catenaryDataCoords,
    setCatenaryDataCoords,
  } = useAppData();
  const initialReferences =
    projectData.references.length > 0 ? projectData.references : [''];
  const {
    references,
    addNewReference,
    removeReference,
    copyReference,
    updateReference,
  } = useReferences(initialReferences);

  const [reportInfo, setReportInfo] = useState<ReportInfoFields>(
    projectData.infoSection
  );
  const [reportIsLoading, setReportIsLoading] = useState<boolean>(false);

  useEffect(() => {
    // TODO: DKL change this code, a lot of assumptions are made here
    if (appResultsData) {
      const catenaryCoordinateList = appResultsData.data.catenaryCoordinates;
      const catenaryCoordinate3 = catenaryCoordinateList[2] || [];
      let catenaryCoordinates = catenaryCoordinateList[0];
      if (catenaryCoordinate3.length > 0 && catenaryCoordinate3[0].length > 0) {
        catenaryCoordinates = catenaryCoordinate3;
      }
      updateCatenaryData(
        'Catenary-1',
        catenaryCoordinates[0] as number[],
        catenaryCoordinates[1] as number[],
        catenaryCoordinates[2] as boolean[],
        setCatenaryDataCoords
      );
      updateCatenaryData(
        'Catenary-2',
        catenaryCoordinateList[1][0] as number[],
        catenaryCoordinateList[1][1] as number[],
        catenaryCoordinateList[1][2] as boolean[],
        setCatenaryDataCoords
      );
    }

    //   Initialize calculations data
    const newCalculationsData = [...applicationData.calculationsData];
    if (newCalculationsData) {
      let catenaryCount = 0;
      newCalculationsData.forEach((item) => {
        if (item.title === 'Catenary') {
          catenaryCount++;
          item.id = `Catenary-${catenaryCount}`;
        } else if (item.title === 'MCT') {
          item.id = 'MCT-1';
        }
      });
      const updatedAppData: ApplicationDataType = applicationData;
      updatedAppData.calculationsData = newCalculationsData;
      setApplicationData(updatedAppData);
    }
  }, []);

  const handleReportLabelInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      const { name, value, type, checked } = e.target;
      const updatedReportInfo: ReportInfoFields = {
        ...reportInfo,
        [name]: type === 'checkbox' ? checked : value,
      };
      const updatedProjectData: ProjectData = projectData;
      updatedProjectData.infoSection = updatedReportInfo;

      setReportInfo(updatedReportInfo);
      setProjectData(updatedProjectData);
    },
    [projectData, reportInfo, setProjectData]
  );

  const handleReferenceChange = useCallback(
    (index: number, e: ChangeEvent<HTMLInputElement>): void => {
      const newReferences: string[] = [...references];
      newReferences[index] = e.target.value;
      updateReference(index, e.target.value);
      const updatedProjectData: ProjectData = {
        ...projectData,
        references: newReferences,
      };
      setProjectData(updatedProjectData);
    },
    [projectData, references, setProjectData, updateReference]
  );

  const handleIntroTextChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>): void => {
      const updatedReportInfo: ReportInfoFields = {
        ...reportInfo,
        introductionText: e.target.value,
      };
      setReportInfo(updatedReportInfo);
      const updatedProjectData: ProjectData = {
        ...projectData,
        infoSection: updatedReportInfo,
      };
      setProjectData(updatedProjectData);
    },
    [projectData, reportInfo, setProjectData]
  );

  const MCTRef = useRef<Stage>(null);
  const CatenaryRef1 = useRef<Stage>(null);
  const CatenaryRef2 = useRef<Stage>(null);

  const handleSubmitBtnClicked = () => {
    const imageList: ImageType[] = [];
    if (MCTRef.current) {
      imageList.push({
        idx: 'MCT-1',
        image: MCTRef.current.toDataURL({ pixelRatio: 2 }),
        include: includeMCTVisuals,
      });
    }
    if (CatenaryRef1.current) {
      imageList.push({
        idx: 'Catenary-1',
        image: CatenaryRef1.current.toDataURL({ pixelRatio: 2 }),
        include: includeCatenaryVisuals,
      });
    }

    if (CatenaryRef2.current) {
      imageList.push({
        idx: 'Catenary-2',
        image: CatenaryRef2.current.toDataURL({ pixelRatio: 2 }),
        include: includeCatenaryVisuals,
      });
    }

    setReportIsLoading(true);
    const reportData: ReportData = {
      calculationsData: applicationData,
      projectData: projectData,
      imageData: imageList,
    };
    const reportName = `${reportData.projectData.infoSection.projectTitle}-Cable Transfer Analysis-${reportData.projectData.infoSection.calculationNumber}-${reportData.projectData.infoSection.revision}`;
    void fetchReport(reportData, reportName).then(() =>
      setReportIsLoading(false)
    );
  };

  const [includeMCTVisuals, setIncludeMCTVisuals] = useState<boolean>(true);

  const clickOnIncludeMctButton = () => {
    setIncludeMCTVisuals(!includeMCTVisuals);
  };

  const [includeCatenaryVisuals, setIncludeCatenaryVisuals] =
    useState<boolean>(true);

  const clickOnIncludeCatenaryButton = () => {
    setIncludeCatenaryVisuals(!includeCatenaryVisuals);
  };

  const mctData = (data: CalculationDataInputs[]) => {
    return (
      (data.find((item) => item.id === 'MCT-1') as ModularCableInputs) ||
      initialMCTData
    );
  };
  const catenaryData = (data: CalculationDataInputs[], id: string) => {
    return (
      (data.find((item) => item.id === id) as CatenaryDataInputs) ||
      initialCatenaryData
    );
  };

  const catenaryCoordinatesId = (id: string, data: CatenaryCoordinates[]) => {
    return data.find((item: CatenaryCoordinates) => item.id == id);
  };

  return (
    <>
      <ReportsDiv>
        <StyledContentDiv>
          <CategoryHeader title="Content" underline={false} />
          <StyledTextarea
            placeholder="Provide a custom introduction here."
            value={reportInfo.introductionText}
            onChange={handleIntroTextChange}
          />
          <CategoryHeader title="References" underline={false} />
          {references.map((reference: string, index: number) => (
            <ReferenceInput
              key={index}
              name="Reference"
              label={`${index + 1}.`}
              inputPlaceHolder="Reference"
              onAddClick={addNewReference}
              onCopyClick={() => copyReference(index)}
              onDeleteClick={() => removeReference(index)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleReferenceChange(index, e)
              }
              value={reference}
            />
          ))}
        </StyledContentDiv>
        <StyledInfoDiv>
          <CategoryHeader title="Details" underline={false} />
          {inputConfigurations.map(
            ({ label, name, placeholder }: TextInputConfiguration) => (
              <TextInput
                key={name}
                label={label}
                name={name}
                inputPlaceHolder={placeholder}
                value={reportInfo[name as keyof ReportInfoTextFields]}
                onChange={handleReportLabelInputChange}
              />
            )
          )}
          <TextCheckbox
            label="Include Reaction Time"
            name="reactionTime"
            value={reportInfo.reactionTime}
            onChange={handleReportLabelInputChange}
          />
          <TextCheckbox
            label="Include MCT Figure"
            name="MCTFigure"
            value={includeMCTVisuals}
            onChange={clickOnIncludeMctButton}
          />
          <TextCheckbox
            label="Include Catenary Figures"
            name="CatenaryFigure"
            value={includeCatenaryVisuals}
            onChange={clickOnIncludeCatenaryButton}
          />
        </StyledInfoDiv>
      </ReportsDiv>
      <div
        style={{
          width: '100%',
          height: '200px',
          display: 'flex',
          alignItems: 'end',
          justifyContent: 'right',
        }}
      >
        {resultsLoading || reportIsLoading ? (
          <div
            style={{
              width: '174px',
              height: '43px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '12px',
            }}
          >
            <CircleLoader size={'65px'} />
          </div>
        ) : (
          <SubmitButton
            title="Download Report"
            onClick={handleSubmitBtnClicked}
          />
        )}
      </div>
      <div hidden={true}>
        <ModularCableTrackVisualizations
          containerWidth={containerWidth || 1515}
          dataRow={mctData(applicationData.calculationsData).data.input}
          rotation={mctData(applicationData.calculationsData).data.rotation}
          selectedSection={0}
          setSelectedSection={() => 0}
          mctVisualizationRef={MCTRef}
        />
        <CatenaryVisualization
          catenaryData={catenaryData(
            applicationData.calculationsData,
            'Catenary-1'
          )}
          catenaryCoordinates={
            catenaryCoordinatesId('Catenary-1', catenaryDataCoords) || {
              id: 'Catenary-1',
              xCoords: [],
              yCoords: [],
              isValidList: [],
            }
          }
          catenaryVisualizationRef={CatenaryRef1}
        />
        <CatenaryVisualization
          catenaryData={catenaryData(
            applicationData.calculationsData,
            'Catenary-2'
          )}
          catenaryCoordinates={
            catenaryCoordinatesId('Catenary-2', catenaryDataCoords) || {
              id: 'Catenary-2',
              xCoords: [],
              yCoords: [],
              isValidList: [],
            }
          }
          catenaryVisualizationRef={CatenaryRef2}
        />
      </div>
    </>
  );
}

const ReportsDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2em;
`;
const StyledContentDiv = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  gap: 0.8em;
`;
const StyledInfoDiv = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  gap: 1em;
`;
const StyledTextarea = styled.textarea`
  align-self: stretch;
  width: 100%;
  min-height: 420px;
  border: rgb(204, 204, 204) solid 1px;
  border-radius: 5px;
  padding: 0.3em 0.8em;
  box-sizing: border-box;
  text-align: left;
  line-height: 1.5em;
  resize: none;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
`;
