import React, { ReactElement, useState } from 'react';
import BceData from '../../assets/component_dimensions/BceData.svg';
import styled from 'styled-components';
import { CategoryHeader } from '../Labels/CategoryHeader';
import { DataInput } from '../Inputs/DataInput';
import { useHandleInputChange, useHandleOnBlurChange } from './InputGroupHooks';
import { BowCableInputs, CalculationSectionProps } from './inputGroupTypes';
import { DataInputs } from '../Inputs/InputTypes';
import { CalculationDataInputs } from '../CalculationsTab/calculationsTabTypes';
import { initialBceData } from '../InitialData/InitialData';

const bowCableLimits: Record<
  keyof BowCableInputs['data'],
  { min: number; max: number }
> = {
  frictionCoef: { min: 0, max: 10 },
  h1distance: { min: 1, max: 1000 },
  h2distance: { min: 1, max: 1000 },
  w1distance: { min: 1, max: 1000 },
  w2distance: { min: 1, max: 1000 },
};
function validateValue<K extends keyof BowCableInputs['data']>(
  key: K,
  value: number
): boolean {
  const limits = bowCableLimits[key];
  return value >= limits.min && value <= limits.max;
}

export function BowCableEngine(props: CalculationSectionProps): ReactElement {
  const initialData: CalculationDataInputs =
    props.applicationData.calculationsData.find(
      (section: CalculationDataInputs): boolean => section.id === props.id
    ) || initialBceData;
  const [bowCableData, setBowCableData] = useState<BowCableInputs>(
    initialData as BowCableInputs
  );
  const handleInputChange = useHandleInputChange(setBowCableData);
  const handleOnBlurChange = useHandleOnBlurChange({
    id: props.id,
    data: bowCableData,
    calculationDataState: props.calculationDataState,
  });

  return (
    <>
      <StyledDiv>
        <InputSection>
          <CategoryHeader title="BCE (Bow Cable Engine)" underline={false} />
          {Object.entries(bowCableData.data).map(
            ([key, input]: [string, DataInputs]) => {
              const valueOk = (value: number) =>
                validateValue(key as keyof BowCableInputs['data'], value);
              return (
                <DataInput
                  key={key}
                  name={input.name}
                  symbol={input.symbol}
                  value={input.value}
                  placeholder={input.value}
                  unit={input.unit}
                  valueOk={valueOk(input.value)}
                  onChange={handleInputChange(
                    key as keyof BowCableInputs['data'],
                    valueOk
                  )}
                  onBlur={handleOnBlurChange}
                  restrictionText={
                    key === 'frictionCoef'
                      ? 'Value must be between 0 and 10'
                      : 'Value must be between 1 and 1000'
                  }
                />
              );
            }
          )}
        </InputSection>
        <div>
          <img src={BceData} alt={''} />
        </div>
      </StyledDiv>
    </>
  );
}

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 60%;
  gap: 0.8em;
`;
const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;
