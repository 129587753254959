import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as LoginLogo } from '../../assets/LoginLogo.svg';
import RedExclamation from '../../assets/buttons_and_icons/RedExclamation.svg';
import { LoginProps } from './loginTypes';
import { CategoryHeader } from '../Labels/CategoryHeader';

export function Login(props: LoginProps): ReactElement {
  const [inputUsername, setInputUsername] = useState<string>('');
  const [inputPassword, setInputPassword] = useState<string>('');
  const [error, setError] = useState<string>('');

  const handleLogin = (): void => {
    const envUsername: string | undefined = process.env.REACT_APP_USERNAME;
    const envPassword: string | undefined = process.env.REACT_APP_PASSWORD;

    if (inputUsername === envUsername && inputPassword === envPassword) {
      props.onLoginSuccess();
    } else {
      setError('Invalid username or password');
    }
  };

  useEffect(() => {
    const onPressEnter = (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        handleLogin();
      }
    };
    document.addEventListener('keydown', onPressEnter);
    return () => document.removeEventListener('keydown', onPressEnter);
  }, [handleLogin]);

  const handleChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      setter(event.target.value);
    };

  return (
    <StyledContainer>
      <StyledDiv>
        <HeaderRow>
          <LoginLogo style={{ height: '80px' }} />
        </HeaderRow>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CategoryHeader title="Cable Transfer Analysis" underline={false} />
        </div>
        <div>
          <StyledInput
            type="text"
            value={inputUsername}
            placeholder="Username"
            onChange={handleChange(setInputUsername)}
          />
        </div>
        <div>
          <StyledInput
            type="password"
            value={inputPassword}
            placeholder="Password"
            onChange={handleChange(setInputPassword)}
          />
          {error && (
            <ErrorRow>
              <img
                src={RedExclamation}
                alt={''}
                style={{ height: '1em', padding: '0.5em' }}
              />
              <StyledError>{error}</StyledError>
            </ErrorRow>
          )}
        </div>
        <StyledButton onClick={handleLogin}>Log in</StyledButton>
      </StyledDiv>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;
const StyledDiv = styled.div`
  min-width: 350px;
  display: flex;
  flex-direction: column;
  flex-basis: 20%;
  align-self: center;
  padding: 2em;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0 0 0 2px var(--twd_web_grey);
  border-radius: 12px;
  gap: 1.5em;
`;
const HeaderRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ErrorRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5em;
`;

const StyledInput = styled.input`
  align-self: center;
  width: 100%;
  border: rgb(204, 204, 204) solid 1px;
  border-radius: 5px;
  text-align: left;
  padding: 0.3em 0.8em 0.3em 0.8em;
  box-sizing: border-box;
  height: 40px;
`;
const StyledText = styled.div`
  color: #333333;
  font-size: 14px;
  line-height: 1.4em;
  font-family: Arial, sans-serif;
  font-weight: 400;
  padding-bottom: 0.5em;
  padding-top: 0.5em;
`;
const StyledError = styled(StyledText)`
  color: #dd1c1a;
`;

const StyledButton = styled.button`
  width: 40%;
  height: 43px;
  color: #fffbfe;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00adef;
  border: 0;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px #d3d3d3;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    opacity: 0.8;
    box-shadow: inset 0 0 0 1px #00adef;
  }
`;
