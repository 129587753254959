import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { CategoryLabelProps } from './labelTypes';
import { FrictionTooltip } from '../Tooltip/FrictionTooltip';
import { IconButton } from '../Button/IconButton';
import TooltipIcon from '../../assets/buttons_and_icons/TooltipIcon.svg';

export function CategoryHeader(props: CategoryLabelProps): ReactElement {
  return (
    <CategoryHeaderSection>
      <StyledHeader $underline={props.underline}>{props.title}</StyledHeader>{' '}
      {props.infoModal ? (
        <FrictionTooltip hoverContent={props.infoModal}>
          <IconButton imgSrc={TooltipIcon} />
        </FrictionTooltip>
      ) : null}
      {props.extraElement ? props.extraElement : null}
    </CategoryHeaderSection>
  );
}

const CategoryHeaderSection = styled.div`
  display: flex;
  gap: 0.5em;
  align-items: center;
`;

const StyledHeader = styled.div<{ $underline: boolean }>`
  background-color: #ffffff;
  height: 30px;
  color: #333333;
  border: none;
  font-size: 20px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  overflow: auto;

  &:after {
    content: '';
    display: block;
    height: 4px;
    background-color: #00adef;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: ${({ $underline }) => ($underline ? '100%' : '0')};
`;
