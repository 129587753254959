import { ModularCableData } from './inputGroupTypes';
import { useState } from 'react';

export const useModularCableTrack = (
  initialDataRow: ModularCableData | ModularCableData[],
  initialMCTColumn: ModularCableData
) => {
  const [dataRow, setDataRow] = useState<ModularCableData[]>(
    Array.isArray(initialDataRow) ? initialDataRow : [initialDataRow]
  );

  const addRow = (): void => {
    if (dataRow.length < 10) {
      setDataRow([...dataRow, initialMCTColumn]);
    }
  };

  const copyRow = (index: number): void => {
    if (dataRow.length < 10) {
      const newSegment: ModularCableData = dataRow[index];
      setDataRow([...dataRow, newSegment]);
    }
  };

  const removeRow = (index: number): void => {
    setDataRow((prev: ModularCableData[]): ModularCableData[] =>
      prev.length > 1
        ? prev.filter((_: ModularCableData, i: number): boolean => i !== index)
        : prev
    );
  };

  const handleInputChange = (
    index: number,
    field: keyof ModularCableData,
    value: number
  ): void => {
    const newValue: number = value === null ? 0 : Number(value);
    const updatedDataRow: ModularCableData[] = dataRow.map(
      (row: ModularCableData, i: number): ModularCableData => {
        if (i === index) {
          const valueOk = validateRow({
            ...row,
            [field]: { ...row[field], value: newValue },
          });
          return {
            ...row,
            [field]: { ...row[field], value: newValue, valueOk: valueOk },
          };
        }
        return row;
      }
    );
    setDataRow(updatedDataRow);
  };

  const validateRow = ({
    length,
    angle,
    friction,
  }: ModularCableData): boolean =>
    length.value >= 0 &&
    length.value <= 1000 &&
    angle.value >= -90 &&
    angle.value <= 90 &&
    friction.value >= 0 &&
    friction.value <= 10;

  return {
    dataRow,
    addRow,
    copyRow,
    removeRow,
    handleInputChange,
    validateRow,
  };
};
