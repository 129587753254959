import React, { ReactElement, useEffect, useState } from 'react';
import { ScenarioBanner } from '../ScenarioBanner/ScenarioBanner';
import { useScenario } from '../Contexts/ScenarioContext/ScenarioContext';
import { Catenary } from '../InputGroup/Catenary';
import { Chute } from '../InputGroup/Chute';
import { BowCableEngine } from '../InputGroup/BowCableEngine';
import { ScenarioName } from '../ScenarioBanner/scenarioTypes';
import { ModularCableTrack } from '../InputGroup/ModularCableTrack';
import styled from 'styled-components';
import { ApplicationDataType } from '../Contexts/DataContext/dataContextType';
import { useAppData } from '../Contexts/DataContext/DataContext';
import {
  CalculationSectionProps,
  ModularCableData,
} from '../InputGroup/inputGroupTypes';
import { fetchCalculationResults } from '../../services/swegApi';
import { CalculationDataInputs } from './calculationsTabTypes';
import { CableTransferAnalysis } from '../RightSection/rightSectionTypes';
import { DataInputs } from '../Inputs/InputTypes';
import { toast } from 'react-toastify';

const IsEveryValueOk = (calculationData: CalculationDataInputs[]) => {
  return calculationData.every((calcData: CalculationDataInputs) => {
    const dataInputs = calcData.data;
    return Object.entries(dataInputs).every(([key, value]) => {
      if (key === 'rotation') {
        return true;
      }
      if (key === 'input') {
        return Object.values(value as ModularCableData[]).every(
          (value) =>
            value.length.valueOk &&
            value.angle.valueOk &&
            value.friction.valueOk
        );
      }
      return (value as DataInputs).valueOk;
    });
  });
};

export function CalculationsTab(): ReactElement {
  const { selectedScenario } = useScenario();
  const {
    applicationData,
    appResultsData,
    setApplicationData,
    setResultsData,
    setResultsLoading,
    setDataValid,
  } = useAppData();
  const [calculationData, setCalculationData] = useState<
    CalculationDataInputs[]
  >([]);
  const renderComponent = (
    Component: React.ComponentType<CalculationSectionProps>,
    id: string
  ) => (
    <Component
      key={id}
      id={id}
      calculationDataState={setCalculationData}
      applicationData={applicationData}
      resultData={appResultsData}
    />
  );

  const CalculationScenario: Record<ScenarioName, ReactElement[]> = {
    'Scenario 1: Vessel to Tank': [
      renderComponent(Catenary, 'Catenary-1'),
      renderComponent(Chute, 'Chute-1'),
      renderComponent(ModularCableTrack, 'MCT-1'),
      renderComponent(BowCableEngine, 'BCE-1'),
    ],
    'Scenario 2: Tank to Tank': [
      renderComponent(BowCableEngine, 'BCE-1'),
      renderComponent(ModularCableTrack, 'MCT-1'),
      renderComponent(BowCableEngine, 'BCE-2'),
    ],
    'Scenario 3: Factory to Tank': [
      renderComponent(ModularCableTrack, 'MCT-1'),
      renderComponent(BowCableEngine, 'BCE-1'),
    ],
    'Scenario 4a: Vessel to Vessel (CAT)': [
      renderComponent(Catenary, 'Catenary-1'),
      renderComponent(Chute, 'Chute-1'),
      renderComponent(ModularCableTrack, 'MCT-1'),
      renderComponent(Catenary, 'Catenary-2'),
      renderComponent(Chute, 'Chute-2'),
    ],
    'Scenario 4b: Vessel to Vessel (BCE)': [
      renderComponent(BowCableEngine, 'BCE-1'),
      renderComponent(ModularCableTrack, 'MCT-1'),
      renderComponent(Catenary, 'Catenary-1'),
      renderComponent(Chute, 'Chute-1'),
    ],
    'Scenario 5a: Tank to Vessel (CAT)': [
      renderComponent(BowCableEngine, 'BCE-1'),
      renderComponent(ModularCableTrack, 'MCT-1'),
      renderComponent(Catenary, 'Catenary-1'),
      renderComponent(Chute, 'Chute-1'),
    ],
    'Scenario 5b: Tank to Vessel (BCE)': [
      renderComponent(BowCableEngine, 'BCE-1'),
      renderComponent(ModularCableTrack, 'MCT-1'),
      renderComponent(BowCableEngine, 'BCE-2'),
    ],
    'Scenario 6a: Vessel to Reel': [
      renderComponent(Catenary, 'Catenary-1'),
      renderComponent(Chute, 'Chute-1'),
      renderComponent(ModularCableTrack, 'MCT-1'),
    ],
    'Scenario 6b: Tank to Reel': [
      renderComponent(BowCableEngine, 'BCE-1'),
      renderComponent(ModularCableTrack, 'MCT-1'),
    ],
  };

  useEffect((): void => {
    setResultsData(undefined);
    const allValuesOk = IsEveryValueOk(calculationData);
    const updatedAppData: ApplicationDataType = applicationData;
    updatedAppData.calculationsData = calculationData;
    setApplicationData(updatedAppData);

    const fetchData = async () => {
      setResultsLoading(true);
      const data: Response | undefined =
        await fetchCalculationResults(applicationData);
      if (data?.ok) {
        const result: CableTransferAnalysis =
          (await data.json()) as CableTransferAnalysis;
        setResultsData(result);
        setDataValid(true);
      } else {
        setDataValid(false);
      }
      setResultsLoading(false);
    };
    if (updatedAppData.calculationsData.length) {
      if (allValuesOk) void fetchData();
      else {
        toast.error('Results are not updated due to invalid input', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
    }
  }, [calculationData, applicationData, setApplicationData]);

  return (
    <StyledDiv>
      <ScenarioBanner scenario={selectedScenario} />
      {CalculationScenario[selectedScenario]}
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8em;
`;
