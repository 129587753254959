import React from 'react';
import { styled } from 'styled-components';

export function Divider() {
  return (
    <StyledDividerDiv>
      <StyledDivider />
    </StyledDividerDiv>
  );
}

const StyledDividerDiv = styled.div`
  width: 100%;
  background: white;
  display: flex;
  justify-content: center;
`;

const StyledDivider = styled.hr`
  width: 100%;
  border: none;
  border-top: 2px solid #cccccc;
  border-radius: 8px;
`;
