import styled from 'styled-components';
import React, { ReactElement } from 'react';
import { IconButtonProps } from './buttonTypes';

export function IconButton(props: IconButtonProps): ReactElement {
  return (
    <StyledLogoButton
      onClick={props.onClick}
      disabled={props.disabled}
      $isDelete={props.isDelete || false}
    >
      {typeof props.imgSrc === 'string' ? (
        <img src={props.imgSrc} alt={''} style={{ height: '1.2em' }} />
      ) : (
        <props.imgSrc />
      )}
    </StyledLogoButton>
  );
}
const StyledLogoButton = styled.button<{ $isDelete: boolean }>`
  width: 20px;
  height: 20px;
  border: 0;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  margin: 0;

  svg {
    fill: var(--twd_web_grey);

    &:hover {
      fill: ${({ $isDelete }) =>
        $isDelete ? 'var(--twd_signal)' : 'var(--twd_aqua)'};
    }
  }
`;
