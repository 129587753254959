import { Circle, Layer, Stage, Text, Image, Line } from 'react-konva';
import { ModularCableData } from '../InputGroup/inputGroupTypes';
import React, { RefObject, useEffect, useState } from 'react';
import BeginSecFigure from '../../assets/JogLine_single.svg';
import EndSecFigure from '../../assets/JogLine_double.svg';
import EngineFigure from '../../assets/engine_icon.svg';
import Konva from 'konva';

interface ScaledMCTData {
  length: number;
  angle: number;
}

const cableHeight = 6;
const storageImageHeight = 30;
const initialHeight = 100;

function calculateScaledSegments(
  mctData: ModularCableData[],
  viewWidth: number,
  rotation: number
): ScaledMCTData[] {
  let xPosition = rotation === 0 ? 0 : viewWidth / 2;
  let smallestPos = xPosition;
  let greatestPos = xPosition;
  const startPos = rotation === 0 ? 0 : viewWidth / 2;
  let lastAngle = -rotation;
  // Calculate the cumulative x position
  const segments = mctData.map((segment) => {
    const factoredLength = segment.length.value * 10;
    const newAngle = lastAngle - segment.angle.value;
    const angleRadians = (Math.PI / 180) * newAngle;
    xPosition += factoredLength * Math.cos(angleRadians);
    smallestPos = Math.min(smallestPos, xPosition);
    greatestPos = Math.max(greatestPos, xPosition);
    lastAngle = newAngle;
    return { ...segment, length: factoredLength };
  });
  // Check if scaling is needed
  if (
    viewWidth !== 0 &&
    (greatestPos > viewWidth - storageImageHeight ||
      smallestPos < storageImageHeight)
  ) {
    const scalingRatio =
      (viewWidth - startPos - storageImageHeight) /
      Math.max(greatestPos, viewWidth - smallestPos);
    return segments.map((segment) => {
      return {
        length: segment.length * scalingRatio,
        angle: segment.angle.value,
      };
    });
  }
  return segments.map((segment) => {
    return { length: segment.length, angle: segment.angle.value };
  });
}

interface CableInfo {
  x: number;
  y: number;
  nextPointX: number;
  nextPointY: number;
  length: number;
  angle: number;
}

interface MCTVisualizationProps {
  mctVisualizationRef: RefObject<Konva.Stage>;
  containerWidth: number;
  dataRow: ModularCableData[];
  rotation: number;
  selectedSection: number;
  setSelectedSection: (index: number) => void;
}

export function ModularCableTrackVisualizations(props: MCTVisualizationProps) {
  const [segments, setSegments] = useState<ScaledMCTData[]>([]);

  useEffect(() => {
    const scaledSegments = calculateScaledSegments(
      props.dataRow,
      props.containerWidth,
      props.rotation
    );
    setSegments(scaledSegments);
  }, [props.dataRow, props.containerWidth, props.rotation]);

  const [cableInfoList, setCableInfoList] = useState<CableInfo[]>([]);
  const [lastPosition, setLastPosition] = useState<CableInfo | null>(null);
  const [viewHeight, setViewHeight] = useState<number>(initialHeight);

  useEffect(() => {
    let x = props.rotation === 0 ? 0 : props.containerWidth / 2;
    let y = initialHeight - storageImageHeight;
    let lowestY = y;
    let highestY = y;
    let angle = -props.rotation;
    let newCableInfo: CableInfo[] = segments.reduce<CableInfo[]>(
      (result, segment) => {
        const newAngle = angle - segment.angle;
        const angleRadians = (Math.PI / 180) * newAngle;
        const newPointX = x + segment.length * Math.cos(angleRadians);
        const newPointY = y + segment.length * Math.sin(angleRadians);
        const newEntry: CableInfo = {
          x: x,
          y: y,
          nextPointX: newPointX,
          nextPointY: newPointY,
          length: segment.length,
          angle: newAngle,
        };
        x = newPointX;
        y = newPointY;
        angle = newAngle;
        if (y > highestY) highestY = y;
        if (y < lowestY) lowestY = y;
        return [...result, newEntry];
      },
      []
    );
    const topDiff =
      lowestY < storageImageHeight ? storageImageHeight - lowestY : null;
    let newViewHeight = initialHeight;
    if (topDiff) {
      newViewHeight += topDiff;
      highestY += topDiff;
    }
    const bottomDiff =
      highestY > newViewHeight - storageImageHeight ? highestY : null;
    if (bottomDiff) {
      newViewHeight = highestY + storageImageHeight;
    }

    newCableInfo = newCableInfo.map((cableInfo) => {
      return {
        ...cableInfo,
        y: cableInfo.y + (topDiff ? topDiff : 0),
        nextPointY: cableInfo.nextPointY + (topDiff ? topDiff : 0),
      };
    });
    setViewHeight(newViewHeight);
    setLastPosition({
      x: x,
      y: y + (topDiff ? topDiff : 0),
      nextPointX: x,
      nextPointY: y + (topDiff ? topDiff : 0),
      length: 0,
      angle: angle,
    });
    setCableInfoList(newCableInfo);
  }, [segments, props.rotation]);

  return (
    <Stage
      width={props.containerWidth}
      height={viewHeight}
      ref={props.mctVisualizationRef}
    >
      <Layer>
        {cableInfoList.map((cableInfo: CableInfo, index: number) => {
          return (
            <CableSection
              key={index + 1}
              index={index + 1}
              position={cableInfo}
              length={cableInfo.length}
              angle={cableInfo.angle}
              is90DegreesRotated={props.rotation === 90}
              selectedSection={props.selectedSection}
              setSelectedSection={props.setSelectedSection}
            />
          );
        })}
        <VisualImages
          lastPosition={lastPosition}
          viewHeight={viewHeight}
          cableInfoList={cableInfoList}
          rotation={props.rotation}
        />
      </Layer>
    </Stage>
  );
}

interface CableSectionProps {
  index: number;
  position: CableInfo;
  length: number;
  angle: number;
  is90DegreesRotated: boolean;
  selectedSection: number;
  setSelectedSection: (index: number) => void;
}

function CableSection(props: CableSectionProps) {
  const [isHoveringOver, setIsHoveringOver] = useState<boolean>(false);
  const [middlePos, setMiddlePos] = useState<CableInfo | null>(null);

  useEffect(() => {
    const angleInRadians = (-props.angle * Math.PI) / 180;
    const middleX =
      props.position.x + (props.length / 2) * Math.cos(angleInRadians);
    const middleY =
      props.position.y - (props.length / 2) * Math.sin(angleInRadians);
    setMiddlePos({
      x: middleX,
      y: middleY,
      nextPointX: middleX,
      nextPointY: middleY,
      length: props.length,
      angle: props.angle,
    });
  }, [props]);

  return (
    <>
      <Line
        key="catenaryline"
        x={0}
        y={0}
        points={[
          props.position.x,
          props.position.y,
          props.position.nextPointX,
          props.position.nextPointY,
        ]}
        stroke={
          props.selectedSection === props.index || isHoveringOver
            ? 'rgb(0, 48, 107)'
            : 'rgb(0, 173, 239)'
        }
        strokeWidth={cableHeight}
        onClick={() => props.setSelectedSection(props.index)}
        onMouseEnter={() => setIsHoveringOver(true)}
        onMouseLeave={() => setIsHoveringOver(false)}
      />
      {middlePos && (
        <>
          <Circle
            x={middlePos.x}
            y={middlePos.y}
            radius={10}
            fill="white"
            stroke={
              props.selectedSection === props.index || isHoveringOver
                ? 'rgb(0, 48, 107)'
                : 'rgb(0, 173, 239)'
            }
            onClick={() => props.setSelectedSection(props.index)}
            onMouseEnter={() => setIsHoveringOver(true)}
            onMouseLeave={() => setIsHoveringOver(false)}
          />
          <Text
            x={middlePos.x - (props.index === 10 ? 7 : 4)}
            y={middlePos.y - 4}
            text={`${props.index}`}
            fontSize={12}
            fontStyle={'bold'}
            onClick={() => props.setSelectedSection(props.index)}
            onMouseEnter={() => setIsHoveringOver(true)}
            onMouseLeave={() => setIsHoveringOver(false)}
          />
        </>
      )}
      {props.index !== 1 && (
        <>
          <Circle
            x={props.position.x}
            y={props.position.y}
            radius={6}
            fill="white"
          />
          <Circle
            x={props.position.x}
            y={props.position.y}
            radius={4}
            fill="red"
          />
        </>
      )}
    </>
  );
}

interface VisualImagesProps {
  lastPosition: CableInfo | null;
  viewHeight: number;
  cableInfoList: CableInfo[];
  rotation: number;
}

function VisualImages(props: VisualImagesProps) {
  const [beginImage, setBeginImage] = useState<HTMLImageElement | null>(null);
  const [endImage, setEndImage] = useState<HTMLImageElement | null>(null);
  const [engineImage, setEngineImage] = useState<HTMLImageElement | null>(null);
  useEffect(() => {
    const img = new window.Image();
    img.src = BeginSecFigure;
    img.onload = () => {
      setBeginImage(img);
    };

    const img2 = new window.Image();
    img2.src = EndSecFigure;
    img2.onload = () => {
      setEndImage(img2);
    };
    const img3 = new window.Image();
    img3.src = EngineFigure;
    img3.onload = () => {
      setEngineImage(img3);
    };
  }, []);

  return (
    <>
      {props.lastPosition &&
        beginImage &&
        endImage &&
        engineImage &&
        props.cableInfoList.length > 0 && (
          <>
            <Line
              key="BeginLine"
              x={0}
              y={0}
              points={[
                props.cableInfoList[0].x -
                  (props.rotation === 0 ? 0 : storageImageHeight),
                props.cableInfoList[0].y -
                  (props.rotation === 0 ? storageImageHeight : 0),
                props.cableInfoList[0].x +
                  (props.rotation === 0 ? 0 : storageImageHeight),
                props.cableInfoList[0].y +
                  (props.rotation === 0 ? storageImageHeight : 0),
              ]}
              stroke={'black'}
              strokeWidth={3}
            />
            <Image
              image={engineImage}
              x={props.lastPosition.x - storageImageHeight / 2}
              y={props.lastPosition.y - storageImageHeight / 2}
              width={storageImageHeight}
              height={storageImageHeight}
            />
          </>
        )}
    </>
  );
}
