import styled from 'styled-components';
import React, { ReactElement } from 'react';
import Ripple from './Ripple';
import { SubmitButtonProps } from './buttonTypes';

export function SubmitButton(props: SubmitButtonProps): ReactElement {
  return (
    <StyledButton onClick={props.onClick}>
      <Ripple />
      {props.title}
    </StyledButton>
  );
}

const StyledButton = styled.button`
    width: 174px;
    height: 43px;
    margin-Bottom: 12px;
    color: #fffbfe;
    background-color: #00306b;
    font-size: 18px;
    font-weight: 700;
    border: 0;
    cursor: pointer;
    position: relative;
    border-radius: 10px;
    box-shadow: inset 0 0 0 1px #d3d3d3;
    outline: none;
    padding: 10px 12px;
    -webkit-tap-highlight-color: transparent;

    &:hover {
        background-color: #00adef;
        box-shadow: inset 0 0 0 2px #00ADEF;
    }

,
`;
