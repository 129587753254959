import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { CategoryHeader } from '../Labels/CategoryHeader';
import { useScenario } from '../Contexts/ScenarioContext/ScenarioContext';
import { useAppData } from '../Contexts/DataContext/DataContext';
import { ScenarioName } from '../ScenarioBanner/scenarioTypes';
import { ScenarioResultSection } from './ScenarioResults';
import {
  BceResultLabelsScenario1,
  BceResultLabelsScenario2,
  initialBceResultScenario1,
  initialBceResultScenario2,
  initialLceResultsScenario1,
  initialLceResultsScenario3,
  initialLceResultsScenario4a,
  LceResultsLabelsScenario1,
  LceResultsLabelsScenario3,
  LceResultsLabelsScenario4a,
} from './resultsScenarios';
import { updateResults } from './ResultsDataHooks';
import { CircleLoader } from '../CircleLoader/CircleLoader';

export function ResultsData(): ReactElement {
  const { selectedScenario } = useScenario();
  const { appResultsData, resultsLoading, isDataValid } = useAppData();

  const [scenario1Results, setScenario1Results] = useState([
    LceResultsLabelsScenario1,
    BceResultLabelsScenario1,
  ]);
  const [scenario2Results, setScenario2Results] = useState([
    BceResultLabelsScenario1,
    BceResultLabelsScenario2,
  ]);
  const [scenario3Results, setScenario3Results] = useState([
    LceResultsLabelsScenario3,
    BceResultLabelsScenario1,
  ]);
  const [scenario4AResults, setScenario4AResults] = useState([
    LceResultsLabelsScenario1,
    LceResultsLabelsScenario4a,
  ]);
  const [scenario4BResults, setScenario4BResults] = useState([
    BceResultLabelsScenario1,
    LceResultsLabelsScenario3,
    LceResultsLabelsScenario4a,
  ]);
  const [scenario5AResults, setScenario5AResults] = useState([
    BceResultLabelsScenario1,
    LceResultsLabelsScenario3,
    LceResultsLabelsScenario4a,
  ]);
  const [scenario5BResults, setScenario5BResults] = useState([
    BceResultLabelsScenario1,
    LceResultsLabelsScenario3,
    BceResultLabelsScenario1,
  ]);
  const [scenario6AResults, setScenario6AResults] = useState([
    BceResultLabelsScenario1,
  ]);
  const [scenario6BResults, setScenario6BResults] = useState([
    BceResultLabelsScenario1,
    LceResultsLabelsScenario3,
  ]);

  const scenarioOutput: Record<ScenarioName, ReactElement> = {
    'Scenario 1: Vessel to Tank': (
      <>
        <ScenarioResultSection
          title="LCE-1"
          data={scenario1Results[0]}
          fallbackData={isDataValid}
        />
        <ScenarioResultSection
          title="BCE-1"
          data={scenario1Results[1]}
          fallbackData={isDataValid}
        />
      </>
    ),

    'Scenario 2: Tank to Tank': (
      <>
        <ScenarioResultSection
          title="BCE-1"
          data={scenario2Results[0]}
          fallbackData={isDataValid}
        />
        <ScenarioResultSection
          title="BCE-2"
          data={scenario2Results[1]}
          fallbackData={isDataValid}
        />
      </>
    ),
    'Scenario 3: Factory to Tank': (
      <>
        <ScenarioResultSection
          title="LCE-1"
          data={scenario3Results[0]}
          fallbackData={isDataValid}
        />
        <ScenarioResultSection
          title="BCE-1"
          data={scenario3Results[1]}
          fallbackData={isDataValid}
        />
      </>
    ),
    'Scenario 4a: Vessel to Vessel (CAT)': (
      <>
        <ScenarioResultSection
          title="LCE-1"
          data={scenario4AResults[0]}
          fallbackData={isDataValid}
        />
        <ScenarioResultSection
          title="LCE-2"
          data={scenario4AResults[1]}
          fallbackData={isDataValid}
        />
      </>
    ),
    'Scenario 4b: Vessel to Vessel (BCE)': (
      <>
        <ScenarioResultSection
          title="BCE-1"
          data={scenario4BResults[0]}
          fallbackData={isDataValid}
        />
        <ScenarioResultSection
          title="LCE-1"
          data={scenario4BResults[1]}
          fallbackData={isDataValid}
        />
        <ScenarioResultSection
          title="LCE-2"
          data={scenario4BResults[2]}
          fallbackData={isDataValid}
        />
      </>
    ),
    'Scenario 5a: Tank to Vessel (CAT)': (
      <>
        <ScenarioResultSection
          title="BCE-1"
          data={scenario5AResults[0]}
          fallbackData={isDataValid}
        />
        <ScenarioResultSection
          title="LCE-1"
          data={scenario5AResults[1]}
          fallbackData={isDataValid}
        />
        <ScenarioResultSection
          title="LCE-2"
          data={scenario5AResults[2]}
          fallbackData={isDataValid}
        />
      </>
    ),
    'Scenario 5b: Tank to Vessel (BCE)': (
      <>
        <ScenarioResultSection
          title="BCE-1"
          data={scenario5BResults[0]}
          fallbackData={isDataValid}
        />
        <ScenarioResultSection
          title="LCE-1"
          data={scenario5BResults[1]}
          fallbackData={isDataValid}
        />
        <ScenarioResultSection
          title="BCE-2"
          data={scenario5BResults[2]}
          fallbackData={isDataValid}
        />
      </>
    ),
    'Scenario 6a: Vessel to Reel': (
      <>
        <ScenarioResultSection
          title="LCE-1"
          data={scenario6AResults[0]}
          fallbackData={isDataValid}
        />
      </>
    ),
    'Scenario 6b: Tank to Reel': (
      <>
        <ScenarioResultSection
          title="BCE-1"
          data={scenario6BResults[0]}
          fallbackData={isDataValid}
        />
        <ScenarioResultSection
          title="LCE-1"
          data={scenario6BResults[1]}
          fallbackData={isDataValid}
        />
      </>
    ),
  };
  const [lastUpdated, setLastUpdated] = useState<string>('');

  useEffect(() => {
    const currentDate = new Date();
    const currentTime = currentDate.toLocaleTimeString();
    setLastUpdated(currentTime);
  }, [appResultsData]);

  useEffect(() => {
    switch (selectedScenario) {
      case 'Scenario 1: Vessel to Tank':
        updateResults(
          appResultsData,
          [initialLceResultsScenario1, initialBceResultScenario1],
          setScenario1Results,
          [LceResultsLabelsScenario1, BceResultLabelsScenario1]
        );
        break;
      case 'Scenario 2: Tank to Tank':
        updateResults(
          appResultsData,
          [initialBceResultScenario1, initialBceResultScenario2],
          setScenario2Results,
          [BceResultLabelsScenario1, BceResultLabelsScenario2]
        );
        break;
      case 'Scenario 3: Factory to Tank':
        updateResults(
          appResultsData,
          [initialLceResultsScenario3, initialBceResultScenario1],
          setScenario3Results,
          [LceResultsLabelsScenario3, BceResultLabelsScenario1]
        );
        break;
      case 'Scenario 4a: Vessel to Vessel (CAT)':
        updateResults(
          appResultsData,
          [initialLceResultsScenario1, initialLceResultsScenario4a],
          setScenario4AResults,
          [LceResultsLabelsScenario1, LceResultsLabelsScenario4a]
        );
        break;
      case 'Scenario 4b: Vessel to Vessel (BCE)':
        updateResults(
          appResultsData,
          [
            initialBceResultScenario1,
            initialLceResultsScenario3,
            initialLceResultsScenario4a,
          ],
          setScenario4BResults,
          [
            BceResultLabelsScenario1,
            LceResultsLabelsScenario3,
            LceResultsLabelsScenario4a,
          ]
        );
        break;
      case 'Scenario 5a: Tank to Vessel (CAT)':
        updateResults(
          appResultsData,
          [
            initialBceResultScenario1,
            initialLceResultsScenario3,
            initialLceResultsScenario4a,
          ],
          setScenario5AResults,
          [
            BceResultLabelsScenario1,
            LceResultsLabelsScenario3,
            LceResultsLabelsScenario4a,
          ]
        );
        break;
      case 'Scenario 5b: Tank to Vessel (BCE)':
        updateResults(
          appResultsData,
          [
            initialBceResultScenario1,
            initialLceResultsScenario3,
            initialBceResultScenario1,
          ],
          setScenario5BResults,
          [
            BceResultLabelsScenario1,
            LceResultsLabelsScenario3,
            BceResultLabelsScenario1,
          ]
        );
        break;
      case 'Scenario 6a: Vessel to Reel':
        updateResults(
          appResultsData,
          [initialLceResultsScenario1],
          setScenario6AResults,
          [LceResultsLabelsScenario1]
        );
        break;
      case 'Scenario 6b: Tank to Reel':
        updateResults(
          appResultsData,
          [initialBceResultScenario1, initialLceResultsScenario3],
          setScenario6BResults,
          [BceResultLabelsScenario1, LceResultsLabelsScenario3]
        );
        break;
    }
  }, [appResultsData, selectedScenario]);

  const memoizedScenarioOutput = useMemo(
    () => scenarioOutput[selectedScenario],
    [scenarioOutput, selectedScenario]
  );

  return (
    <StyledDiv>
      <HeaderDiv>
        <CategoryHeader title="Results" underline={false} />
        {lastUpdated !== '' && (
          <LastUpdatedDiv>Last Updated {lastUpdated}</LastUpdatedDiv>
        )}
      </HeaderDiv>
      <ResultsDiv>
        <CategoryHeader title={selectedScenario} underline={false} />
        {memoizedScenarioOutput}
      </ResultsDiv>
      <ButtonDiv>{resultsLoading && <CircleLoader size={'65px'} />}</ButtonDiv>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  gap: 0.8em;
  position: relative;
`;

const ResultsDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  padding-bottom: 0.3em;
`;
const ButtonDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: 0;
`;

const HeaderDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LastUpdatedDiv = styled.div`
  height: 30px;
  color: var(--twd_aqua);
  border: none;
  font-size: 14px;
  font-family: Arial, sans-serif;
  display: flex;
  align-items: center;
`;
