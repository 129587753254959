import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { IconButton } from '../Button/IconButton';
import CloseIcon from '../../assets/buttons_and_icons/close_icon.svg';
import { CategoryHeader } from '../Labels/CategoryHeader';
import { ReactComponent as InfoBanner } from '../../assets/other/InfoBanner.svg';
import RedExclamation from '../../assets/buttons_and_icons/RedExclamation.svg';
import BlueExclamation from '../../assets/buttons_and_icons/BlueExclamation.svg';

interface ModalProps {
  show: boolean;
  onClose: () => void;
}

export function InfoModal(props: ModalProps): ReactElement | null {
  if (!props.show) {
    return null;
  }
  return (
    <Overlay onClick={props.onClose}>
      <Content onClick={(e) => e.stopPropagation()}>
        <MainLayout>
          <CloseButton>
            <IconButton imgSrc={CloseIcon} onClick={props.onClose} />
          </CloseButton>
          <TextLayout>
            <LeftSection>
              <CategoryHeader title="Information" underline={false} />
              <StyledSubheader>Background</StyledSubheader>
              <StyledText>
                This app calculates the force required to pull a cable through a
                cable track. The track can include one or more engines, which
                are used to supply this force. Analysis is split with respect to
                the pulling force required by each engine, and calculated from
                the friction and tension forces acting upon the cable.
              </StyledText>
              <StyledSubheader>Project Setup</StyledSubheader>
              <StyledText>
                1. Select cable track from the drop down <br />
                2. Define ‘Cable Data’ and ‘General Input’
              </StyledText>
              <StyledSubheader>Calculations</StyledSubheader>
              <StyledText>
                3. Input required values as requested in each section.
                Preliminary values can be overwritten.
              </StyledText>
              <StyledSubheader>Results</StyledSubheader>
              <StyledText>
                Results are automatically tabulated in the right-pane.
              </StyledText>
              <StyledSubheader>Reaction Time</StyledSubheader>
              <StyledText>
                4. Additional operator reaction time calculation can be included
                at the user’s discretion.
              </StyledText>
              <StyledSubheader>Report</StyledSubheader>
              <StyledText>
                5. Use the ‘Report’ tab to input additional context, details and
                references. <br /> 6. Click ‘Download Report’ for a full
                detailed calculation.
              </StyledText>
            </LeftSection>
            <RightSection>
              <CategoryHeader title="Assumptions" underline={false} />
              <StyledRow>
                <StyledNumber>1</StyledNumber>
                <StyledText>
                  Equipment on the vessel deck or in the factory moves the cable
                  to the start of the selected scenario without applying any
                  additional forces afterward.
                </StyledText>
              </StyledRow>
              <StyledRow>
                <StyledNumber>2</StyledNumber>
                <StyledText>
                  The cable is assumed to be above water level at all times.
                  Potential buoyancy forces due to a partially submerged cable
                  are not considered.
                </StyledText>
              </StyledRow>
              <StyledRow>
                <StyledNumber>3</StyledNumber>
                <StyledText>
                  The self-weight of any rollers the cable comes in contact with
                  is assumed to be negligible compared to the cables own
                  self-weight, and is therefore omitted when calculating the
                  roller friction losses.
                </StyledText>
              </StyledRow>
              <StyledRow>
                <StyledNumber>4</StyledNumber>
                <StyledText>
                  Potential ascending structures along the track will reduce the
                  width and height of the following catenary which will decrease
                  the tension forces in the cable. The effects of ascending
                  structures are therefore not considered.
                </StyledText>
              </StyledRow>
              <StyledRow>
                <StyledNumber>5</StyledNumber>
                <StyledText>
                  To remain conservative, the user must consider the maximum
                  possible values for the height and width of the catenary
                  before the BCE, and the minimum possible values for the height
                  and width for the catenary after the BCE.
                </StyledText>
              </StyledRow>
              <StyledImage>
                <InfoBanner />
              </StyledImage>
            </RightSection>
          </TextLayout>
          <StyledFooter>
            <LeftNote>
              <img
                src={RedExclamation}
                alt={''}
                style={{ height: '1em', padding: '0.5em' }}
              />
              Not all inputs are range restricted: user is responsible for
              validation
            </LeftNote>
            <FooterRow>
              <img
                src={BlueExclamation}
                alt={''}
                style={{ height: '1em', padding: '0.5em' }}
              />
              WIND Cable Transfer Analysis App - version 1.3.2
            </FooterRow>
          </StyledFooter>
        </MainLayout>
      </Content>
    </Overlay>
  );
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  align-items: flex-start;
  justify-content: space-between;
  background-color: white;
  padding: 0.5em;
  border-radius: 8px;
  border: rgb(204, 204, 204) solid 1px;
  position: relative;

  @media (max-width: 1200px) {
    flex-direction: column;
    padding: 1em;
    overflow-y: auto;
    height: 60%;
    width: 80%;
  }
`;
const MainLayout = styled.div`
  display: flex;
  flex-direction: column;
`;
const TextLayout = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 2em;
  padding-right: 2em;
  gap: 2em;

  @media (max-width: 1200px) {
    flex-direction: column;
    padding: 1em;
    gap: 1em;
  }
`;
const CloseButton = styled.div`
  padding: 0.5em;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const LeftSection = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  flex-basis: 50%;
  width: 540px;

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const RightSection = styled(LeftSection)`
  border: 2px solid #00adef;
  border-radius: 20px;
  padding: 24px 32px 48px 32px;

  @media (max-width: 1200px) {
    padding: 16px;
  }
`;

const StyledText = styled.p`
  font-family: Arial, sans-serif;
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
`;

const StyledSubheader = styled(StyledText)`
  font-weight: 700;
  padding-bottom: 0;
  margin-bottom: 0;
`;

const StyledNumber = styled.div`
  font-family: Arial, sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
`;

const StyledRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 1em;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 100%;
    width: 100%;
  }
`;

const StyledFooter = styled.div`
  display: flex;
  padding: 1em 2em 2em 1em;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1em;
  }
`;

const FooterRow = styled.div`
  box-sizing: border-box;
  font-size: 14px;
  display: flex;
  flex-basis: 50%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const LeftNote = styled(FooterRow)`
  color: #dd1c1a;
`;
